import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["display", "form"];

  connect() {
    // Hide the edit form initially
    this.formTarget.classList.add("visually-hidden");
  }

  edit() {
    // Show the edit form and focus on the input
    this.displayTarget.classList.add("visually-hidden");
    this.formTarget.classList.remove("visually-hidden");
    this.formTarget.querySelector("input").focus();
  }

  cancel() {
    // Hide the edit form without saving changes
    this.formTarget.classList.add("visually-hidden");
  }

  submit(event) {
    event.preventDefault();
    // Make an AJAX request to update the content
    const form = this.formTarget;
    const name = this.formTarget.querySelector("input#category_name").value;
    const payload = { category: { name: name } };

    fetch(form.action, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify(payload),
    })
      .then((_data) => {
        this.displayTarget.textContent = name;
        this.displayTarget.classList.remove("visually-hidden");
        this.formTarget.classList.add("visually-hidden");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
}
