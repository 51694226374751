// app/javascript/controllers/tabs_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tabContent"];

  connect() {
    this.showTab(0); // Show the first tab on page load
  }

  toggleTab(event) {
    event.preventDefault();
    const index = this.tabContentTargets.indexOf(event.currentTarget);
    this.showTab(index);
  }

  showTab(index) {
    this.tabContentTargets.forEach((tabContent, i) => {
      if (i === index) {
        tabContent.classList.add("show", "active");
      } else {
        tabContent.classList.remove("show", "active");
      }
    });
  }
}
